<template>
  <div class="alert_wrap" v-if="isShow">
    <div class="alert_box animate__animated animate__slideInDown">
      <div class="alert_title" v-if="title">{{ title }}</div>
      <div class="alert_content"><slot></slot></div>
      <div class="alert_btn space_around">
        <div class="cancel center btn_click" v-if="!hideCancel" @click="handleClick('cancel')">{{ cancelText || '取消' }}</div>
        <div class="confirm center btn_click" @click="handleClick('confirm')"><i v-if="isSubmit" class="el-icon-loading"></i>{{ confirmText || '确定' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    visible(bool) {
      this.isShow = bool;
    },
  },
  methods: {
    handleClick(type) {
      this.$emit(type);
    },
  },
};
</script>

<style lang="less" scoped>
.alert_wrap {
  .fixed_bg;
  z-index: 99;
  .flex-center;
  .alert_box {
    width: 30%;
    .flex-column;
    overflow: hidden;
    margin-top: -4vh;
    position: relative;
    border-radius: 20px;
    background: #101115;
    padding: 20px 20px 26px;
    box-sizing: border-box;
    @media @max750 {
      width: 90%;
    }
    &::before {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(-222deg, rgba(@main, 0.2) 0%, rgba(32, 29, 48, 0) 40%, rgba(248, 125, 81, 0) 100%);
    }
    .alert_title {
      .sc(22px, #fff);
      text-align: center;
      @media @max750 {
        font-size: 20px;
      }
    }
    .alert_content {
      flex: 1;
      z-index: 2;
      padding: 20px 0;
      min-height: 100px;
    }
    .alert_btn {
      width: 100%;
      z-index: 2;
      margin: 0 auto;
      position: relative;
      & > div {
        .wh(120px, 30px);
        .sc(14px, #fff);
      }
      .cancel {
        border: 1px solid #fff;
        background: linear-gradient(360deg, rgba(114, 116, 119, 0.5) 0%, rgba(114, 116, 119, 0.25) 100%);
      }
      .confirm {
        .btn-bg;
      }
    }
  }
}
</style>
