<template>
  <div class="roll_item" :style="{ 'background-image': `url(${require(`../assets/image/roll${index}.png`)})` }">
    <div class="item_left">
      <div class="money">
        <!-- <img src="@/assets/image/wm.png" alt="" />
        <span>{{ rollItem.totalOrnamentsPrice }}</span> -->
        <price :price="rollItem.totalOrnamentsPrice" :size="$mode == 'mobile' ? 'small' : 'large'" />
      </div>
      <img class="cover" :src="maxPriceItem.imgUrl" alt="" />
    </div>
    <div class="item_right">
      <!-- <div class="name">{{ maxPriceItem.ornamentName }}</div> -->
      <div class="name">
        <Rolltitle :name="rollItem.rollName" :id="rollItem.rollId"></Rolltitle>
      </div>
      <div class="time" v-if="downTime" v-html="downTime"></div>
      <div class="join" @click="handleJoin">立即参与</div>
    </div>
    <div v-if="joinCondition" class="recharge">{{ joinCondition }}</div>
  </div>
</template>

<script>
import moment from 'moment';
import { gameConditionById } from '@/api/index';
import Rolltitle from '@/components/Rolltitle.vue';
export default {
  components: {
    Rolltitle,
  },
  props: {
    index: {
      type: Number,
    },
    rollItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timer: null,
      downTime: '',
      rechargeInfo: null,
    };
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  },
  watch: {
    'rollItem.endTime': {
      handler() {
        this.countdown();
        this.queryById();
      },
      immediate: true,
    },
  },
  computed: {
    maxPriceItem() {
      const list = JSON.parse(this.rollItem?.ornamentsList || '[]');
      const maxPriceItem = list.reduce((prev, current) => {
        return prev.price > current.price ? prev : current;
      });
      return maxPriceItem;
    },
    joinCondition() {
      if (!this.rechargeInfo) return;
      let text = '';
      const { rulerConsumeAmount, rulerConsumeBlend, rulerConsumeCredits, rulerRecharge } = this.rechargeInfo;
      const all = [rulerConsumeAmount, rulerConsumeBlend, rulerConsumeCredits, rulerRecharge];
      if (all.every(it => it == 0)) return '所有用户均可参加';
      if (rulerRecharge) text = `充值${rulerRecharge}元` + '\n';
      if (rulerConsumeAmount) text += `消费${rulerConsumeAmount}金币` + '\n';
      if (rulerConsumeCredits) text += `消费${rulerConsumeCredits}积分` + '\n';
      if (rulerConsumeBlend) text += `累计消费${rulerConsumeBlend}` + '\n';
      return text;
    },
  },
  methods: {
    renderTime(t, u) {
      return `<b class="num ${u}">${t > 9 ? t : `0${t}`}</b>${u != 'seconds' ? '<span class="unit">:</span>' : ''}`;
    },
    setTime(timestamp) {
      let hours = Math.floor(timestamp / 1000 / 60 / 60) || 0; // 把天换算到小时中
      let minutes = Math.floor((timestamp / 1000 / 60) % 60) || 0;
      let seconds = Math.floor((timestamp / 1000) % 60) || 0;
      const h = this.renderTime(hours, 'hours');
      const m = this.renderTime(minutes, 'minutes');
      const s = this.renderTime(seconds, 'seconds');
      this.downTime = `${h}${m}${s}`;
    },
    countdown() {
      const endTime = this.rollItem?.endTime || '';
      if (!endTime) return;
      const now = moment();
      // 活动中
      if (now.isBefore(endTime)) {
        const timeDiff = moment().diff(endTime); // 时间差
        const timestamp = Math.abs(timeDiff || 0); // 时间戳
        if (timestamp > 0) {
          this.setTime(timestamp);
          this.timer = setTimeout(this.countdown, 1000);
        } else {
          this.$emit('refresh');
          clearInterval(this.timer);
        }
      } else {
        this.$emit('refresh');
        this.timer && clearInterval(this.timer);
      }
    },
    queryById() {
      if (!this.rollItem.gameConditionId) return;
      gameConditionById(this.rollItem.gameConditionId).then(res => {
        this.rechargeInfo = res?.data?.data || null;
      });
    },
    handleJoin() {
      this.$store.commit('playAudio');
      const { rollId, gameId } = this.rollItem;
      this.$router.push({ path: '/rollDetail', query: { rollId, gameId } });
    },
  },
};
</script>

<style lang="less" scoped>
.roll_item {
  width: 100%;
  height: 200px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center center;
  @media @max750 {
    height: 130px;
    padding: 10px 6px;
  }
  .item_left {
    width: 48%;
    margin: 20px;
    @media @max750 {
      margin: 0;
    }
    .money {
      .flex-a-center;
      img {
        width: 30px;
        @media @max750 {
          width: 14px;
        }
      }
      span {
        padding-left: 6px;
        font-weight: bold;
        .sc(18px, #ff9600);
        font-family: PingFang SC;
        @media @max750 {
          font-size: 12px;
          padding-left: 2px;
          font-weight: normal;
        }
      }
    }
    .cover {
      width: 100%;
      margin-top: 20px;
      @media @max750 {
        margin-top: 10px;
      }
    }
  }
  .item_right {
    width: 48%;
    margin: 20px;
    @media @max750 {
      margin: 0;
      margin-left: 4px;
    }
    .name {
      .ell;
      .sc(18px, #fff);
      @media @max750 {
        font-size: 12px;
      }
    }
    .time {
      width: 90%;
      margin: 10px 0;
      padding: 4px 10px;
      .sc(18px, #fff);
      font-weight: bold;
      border-radius: 6px;
      border: 1px solid #fff;
      @media @max750 {
        margin: 6px 0;
        font-size: 12px;
        padding: 2px 4px;
        border-radius: 4px;
        font-weight: normal;
      }
    }
    .join {
      right: 34px;
      bottom: 34px;
      height: 36px;
      padding: 0 30px;
      position: absolute;
      .sc(18px, #fff);
      text-align: center;
      line-height: 36px;
      border-radius: 20px;
      white-space: nowrap;
      background: #de62ac;
      @media @max750 {
        right: 12px;
        bottom: 22px;
        height: 24px;
        font-size: 12px;
        padding: 0 14px;
        line-height: 24px;
      }
    }
  }
  .recharge {
    left: 0;
    bottom: 0;
    padding: 2px 10px;
    position: absolute;
    .sc(16px, #fedce7);
    background: #c65f5f;
    border-radius: 0 10px 0 10px;
    @media @max750 {
      padding: 1px 6px;
      font-size: 12px;
    }
  }
}
</style>

<style lang="less">
.roll_item .item_right {
  .time {
    .flex-a-center;
    .num,
    .unit {
      flex: 1;
      text-align: center;
    }
    .hours,
    .minutes,
    .seconds {
      position: relative;
      &::after {
        content: '时';
        .abs-cl;
        bottom: -30px;
        opacity: 0.5;
        .sc(14px, #ccc);
        @media @max750 {
          bottom: -24px;
          font-size: 12px;
        }
      }
    }
    .minutes::after {
      content: '分';
    }
    .seconds::after {
      content: '秒';
    }
  }
}
</style>
