import store from '@/vuex/store';
import bsurl from '@/api/basurl.js';

export default class WebSocketService {
  constructor(options) {
    const { url, watchLogin, onmessage, onclose, onerror } = options;
    this.url = url;
    this.socket = null;
    this.stopWs = false; // 是否手动关闭
    this.isConnected = false; // 是否连接中
    this.watchLogin = watchLogin; // 是否监听登录态
    this.reconnectInterval = 3000; // 断开等待3s重连
    this.heartbeatInterval = 3000; // 心跳机制每隔3s发送心跳数据包
    this.heartbeatTimer = null;
    this.reconnectTimer = null;
    this.messageCallback = onmessage || (() => null);
    this.closeCallback = onclose || (() => null);
    this.errorCallback = onerror || (() => null);

    this.connect();
  }

  // 建立ws连接
  connect() {
    if (!this.url) return;
    this.socket = new WebSocket(bsurl.BASE_API + this.url);

    this.socket.onopen = () => {
      console.log('WebSocket open!!!');
      this.isConnected = true;
      this.resetHeartbeat();

      if (!this.watchLogin) return;
      store.dispatch('setWsList', { ws: this.socket, type: 'add' });
    };

    this.socket.onmessage = event => {
      const data = JSON.parse(event.data || '{}');
      // 阻止重复连接
      if (data.code == 501) {
        this.stopWs = true;
        clearInterval(this.heartbeatTimer);
        return;
      }
      if (data.code != 200) return;
      if (data.key == 'HEART_BEAT') return;
      console.log('WebSocket message:', event);
      this.messageCallback(data);
    };

    this.socket.onclose = () => {
      console.log('WebSocket close ===>', this.url);
      store.dispatch('setWsList', { ws: this.socket, type: 'del' });
      this.isConnected = false;
      this.closeCallback();
      this.reconnect();
    };

    this.socket.onerror = error => {
      console.error('WebSocket error:', error);
      this.errorCallback();
      this.socket.close();
    };
  }

  // 断开自动重连
  reconnect() {
    clearTimeout(this.reconnectTimer);
    if (this.socket.readyState === WebSocket.OPEN) return; // 已经连接的不再连了
    if (this.stopWs || store.state.LOGIN_IS_SHOW) return; // 手动断开的不自动连
    this.reconnectTimer = setTimeout(() => {
      this.connect();
    }, this.reconnectInterval);
  }

  // ws心跳机制
  setupHeartbeat() {
    clearInterval(this.heartbeatTimer);
    this.heartbeatTimer = setInterval(() => {
      this.sendMessage(JSON.stringify({ key: 'HEART_BEAT' }));
    }, this.heartbeatInterval);
  }

  // 重置ws连接
  resetHeartbeat() {
    this.setupHeartbeat();
    this.stopWs = false;
  }

  // 发送数据包
  sendMessage(message) {
    if (this.isConnected && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    }
  }

  // 手动关闭ws
  close() {
    console.log('手动关闭ws!!!');
    clearTimeout(this.reconnectTimer);
    clearInterval(this.heartbeatTimer);
    if (!this.socket) return;
    this.socket.close();
    this.stopWs = true;
  }

  // 检查WebSocket连接状态
  checkWsState(ws) {
    switch (ws.readyState) {
      case WebSocket.CONNECTING:
        console.log('连接还未建立，状态为 CONNECTING');
        break;
      case WebSocket.OPEN:
        console.log('连接已建立，状态为 OPEN');
        break;
      case WebSocket.CLOSING:
        console.log('连接正在关闭，状态为 CLOSING');
        break;
      case WebSocket.CLOSED:
        console.log('连接已关闭，状态为 CLOSED');
        break;
      default:
        console.log('未知状态');
    }
  }
}
