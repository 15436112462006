import { render, staticRenderFns } from "./GlobalHeader.vue?vue&type=template&id=68e62036&scoped=true"
import script from "./GlobalHeader.vue?vue&type=script&lang=js"
export * from "./GlobalHeader.vue?vue&type=script&lang=js"
import style0 from "./GlobalHeader.vue?vue&type=style&index=0&id=68e62036&prod&lang=less&scoped=true"
import style1 from "./GlobalHeader.vue?vue&type=style&index=1&id=68e62036&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e62036",
  null
  
)

export default component.exports