<template>
  <el-dialog title="" append-to-body :modal-append-to-body="false" :show-close="false" class="readme_dialoag" :visible.sync="readmeVisible" :close-on-click-modal="false">
    <lineTitle :title="title || '玩法说明'" />
    <div class="content">
      <div class="text" v-html="text"></div>
    </div>
    <div class="close_btn" @click="handleClose" style="text-align: center;">关闭</div>
  </el-dialog>
</template>

<script>
import lineTitle from '@/components/lineTitle.vue';

export default {
  props: {
    visible: {
      type: Boolean,
      defaule: false,
    },
  },
  components: { lineTitle },
  data() {
    return {
      text: '',
      title: '',
      readmeVisible: false,
    };
  },
  beforeCreate() {
    this.$bus.$on('nr_illustrate', res => {
      const { cont, title } = res;
      this.recerL(cont);
      this.title = title || '';
    });
  },
  beforeDestroy() {
    this.$bus.$off('nr_illustrate');
  },
  watch: {
    visible(val) {
      let timer = setTimeout(() => {
        this.readmeVisible = val;
        clearTimeout(timer);
      }, 100);
    },
  },
  methods: {
    playAudio() {
      this.$store.commit('playAudio');
    },
    recerL(res) {
      if (res == 'dream') {
        this.text = `<p>1、选择您想要的目标饰品。</p>
      <p>
        2、随意选取成功百分比以及对应的ROLL点区间，最低追梦概率为5%，最高75%。
      </p>
      <p>3、追梦金额会根据饰品价值和所选区间相应变化。</p>
      <p>
        4、追梦成功，您将获得目标饰品；追梦失败，您将获得随机安慰奖（无论是否成功，追梦完
        毕后指针会默认恢复到最高追梦概率处）。
      </p>`;
      } else if (res == 'pk' || res == 'roomDetail') {
        this.text = `<p>
        1、
        创建竞技场时，可以选择乱斗人数（2人对战，3人对战），一场乱斗最多可以选择
        12个盲盒。
      </p>
      <p>
        2、
        竞技场入场费用即本场竞技开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还费用。
      </p>
      <p>3、 当一场竞技有多名赢家均分奖励时，无法平均分配，则随机分配</p>
      <p>
        4、
        每场游戏的结果受到（创建时间和开始时间等）多方面因素影响，为完全随机产生的结果。
      </p>`;
      } else if (res == 'roll') {
        this.text = `<p>
        1、选择加入roll房间，根据房间规则可以加入房间。
      </p>
      <p>
        2、达到加入条件后，即可加入房间。
      </p>
      <p>3、加入房间，到达房间开奖日期后即可随机分配房间内的饰品。</p>`;
      } else if (res == 'promotion') {
        this.text = `<p>
          1、玩家通过填写您的邀请码或者访问专属邀请链接进行注册，此玩家即成为您名下的推广用户。
        </p>
        <p>
          2、推广的用户在平台进行的消费，推广者将获得相应的消费奖励。奖励会在每日0点后统一自动发放到推广者账户。
        </p>
        <p>3、玩家创建的盲盒，其他玩家或自己打开此盲盒，创建者都将获得创建者奖励（这里的玩家指平台所有玩家）。奖励实时发放到创建者账户。</p>
        <p>
          4、玩家创建盲盒数量与玩家充值数额相关，充值越高可创建的盲盒数量越多。
        </p>
        `;
      } else if (res == 'hongbao') {
        this.text = `
        <h3 style="text-align: center; color:#00F9FF; margin-top: 10px;">等级系统设定规则</h3>
        <div>
          <p>1、充值门槛对应的等级:</p>
          <div style="display: flex;flex-wrap: wrap;">
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">等级</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">等级门槛</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">递减值</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">1</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">100</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">30</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">2</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">500</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">40</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">3</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">1000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">50</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">4</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">2000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">60</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">5</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">3000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">70</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">6</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">5000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">80</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">7</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">7000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">100</div>
            </div>
            <div style="width:70px;border: 1px solid white; margin-bottom: 10px;">
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">8</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">10000</div>
              <div style="height:30px;text-align: center;line-height: 30px; border: 1px solid white;">130</div>
            </div>
          </div>
        </div>
        <p>2、用户需满足充值门槛，获得相应的等级。</p>
        <p>3、充值点数每日0点到0点10分 扣减。  这期间等级宝箱开启功能关闭。</p>
        <p>4、如用户达到4级，可开启小于等于4级的任意等级宝箱，但每天仅允许开启一次。每日零点更新扣减后的积分后，根据新积分判定用户等级，并更新用户可开启等级宝箱的权限。</p>
        <h3 style="text-align: center; color:#00F9FF; margin-top: 10px;">充值签到宝箱设定规则</h3>
        <p>1、充值（限在线充值和卡密）可获得开启权限七天，每天可开启一次。</p>
        <p>2、若在七天内又再次充值，七天时间顺延（不累加）。</p>
        <p>3、如1号充值可在1-7号每天开启，3号又再次充值则开启时间变更为3-9,（同样为7次）。</p>
        <p>4、若1号上午充值并开启过充值签到福利宝箱，下午又充值当天不能再开启，开启时间还为1-7号。</p>
        `;
      } else {
        this.text = res;
      }
      this.$forceUpdate();
    },
    handleClose() {
      this.playAudio();
      const element = this.$refs.confirmel;
      if (element) element.className += ' animate__slideOutDown';
      let timer = setTimeout(() => {
        this.$bus.$emit('is_illustrate', false);
        clearTimeout(timer);
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.readme_dialoag {
  .flex-center;
  /deep/ .el-dialog {
    margin: 0;
    padding: 0;
    width: auto;
    max-width: 576px;
    display: inline-block;
    border-radius: 20px;
    margin: -50px 20px 0 !important;
    background: url(../assets/image/readmebg.png) no-repeat;
    background-size: 100% 100%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 50px 0;
      @media @max750 {
        padding: 30px 0;
      }
    }
    .content {
      min-height: 40vh;
      max-height: 60vh;
      overflow-y: auto;
      padding: 0 40px;
      color: #dbdbdb;
      @media @max750 {
        padding: 0 20px;
      }
      p {
        margin: 20px 0;
        font-size: 18px;
        line-height: 28px;
        @media @max750 {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .close_btn {
      .btn-active(140px, 40px);
      .sc(18px, #fff);
      border-radius: 20px;
      margin: 146px auto 0;
      @media @max750 {
        margin-top: 30px;
        .wh(120px, 34px);
        line-height: 34px;
        font-size: 16px;
      }
    }
  }
}
</style>
