<template>
  <div class="line_title">
    <div class="line"></div>
    <div class="round"><span></span></div>
    <div class="text">{{ title }}</div>
    <div class="round"><span></span></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '盲盒系列',
    },
  },
};
</script>

<style lang="less" scoped>
.line_title {
  display: flex;
  align-items: center;
  justify-content: center;
  .line {
    width: 12%;
    height: 1px;
    display: block;
    background: @main;
    @media @max750 {
      width: 16%;
    }
  }
  .round {
    .wh(14px, 14px);
    margin: 0 6px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    line-height: 14px;
    border: 1px solid @main;
    @media @max750 {
      .wh(10px, 10px);
      margin: 0 4px;
      line-height: 10px;
    }
    span {
      .abs-ctl;
      width: 3px;
      height: 3px;
      display: block;
      background: @main;
      border-radius: 50%;
    }
  }
  .text {
    color: #fff;
    padding: 0 8px;
    font-size: 28px;
    font-weight: bold;
    @media @max750 {
      font-size: 18px;
    }
  }
}
</style>
