<template>
  <div :class="['footer', { margin_t: show }]">
    <template v-if="show">
      <div class="footer_top">
        <div class="logo"><img src="@/assets/image/wlogo.png" alt="" /></div>
        <div class="cont">在顶级盲盒网站上打开带有独家 CS:GO 皮肤的最新箱子。您是否正在寻求 CS:GO 库存的炫酷视觉更新？如果您的答案是“是”，<br v-if="$mode == 'pc'" />那么您绝对应该购买一个想要的箱子来获得它们！</div>
        <div class="menu">
          <div class="item" v-for="(item, index) in topMenu" :key="index">
            <img :src="require(`@/assets/image/${item.icon}.png`)" alt="" />
            <div class="info">
              <p>{{ item.title }}</p>
              <h4>{{ item.count }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="logo2 pc-show"><img src="@/assets/image/wlogo3.png" alt="" /></div>
        <div class="cont">
          <div class="menu">
            <div class="item" v-for="(item, index) in bottomMenu" :key="index" @click="toPath(item.type)">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="info">
            <!-- <span><img src="@/assets/image/gongan.png" alt="" />冀公网安备13011002000473号</span>
            <span>冀ICP备2023038267号</span> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { webInfo } from '@/api/index';

export default {
  data() {
    return {
      info: {},
      bottomMenu: [
        { title: '用户协议', icon: 'el-icon-user-solid', type: 5 },
        { title: '隐私协议', icon: 'el-icon-s-management', type: 2 },
        { title: '关于我们', icon: 'el-icon-info', type: 3 },
        { title: '帮助中心', icon: 'el-icon-question', type: 4 },
        // { title: '反洗钱协议', icon: 'el-icon-s-release', type: 7 },
      ],
    };
  },
  created() {
    this.getWebInfo();
  },
  computed: {
    show() {
      if (this.$store.state.mode == 'pc') return true;
      // 移动端展示footer的页面
      return ['/'].includes(this.$route.path);
    },
    topMenu() {
      const { boxNumber, decorationNumber, gunNumber, meleeNumber, vipNumber } = this.info;
      return [
        { title: '盲盒个数', icon: 'mhgs', count: boxNumber },
        { title: '注册会员', icon: 'zchy', count: vipNumber },
        { title: '枪品武器', icon: 'qpwq', count: gunNumber },
        { title: '印花饰品', icon: 'yhsp', count: decorationNumber },
        { title: '近战武器', icon: 'jzwq', count: meleeNumber },
      ];
    },
  },
  methods: {
    getWebInfo() {
      webInfo().then(res => {
        this.info = res?.data?.data || {};
      });
    },
    toPath(type) {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.$router.push(`/help?type=${type}`);
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  @media @max750 {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS */
    padding-bottom: env(safe-area-inset-bottom); /* Android */
    margin-bottom: @footerH;
  }
  &.margin_t {
    margin-top: 60px;
    @media @max750 {
      margin-top: 40px;
    }
  }
  .footer_top {
    margin-bottom: 80px;
    @media @max750 {
      margin-bottom: 40px;
    }
    .logo2{
      width: 120px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
      @media @max750 {
        width: 60px;
      }
    }
    .logo {
      width: 120px;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
      @media @max750 {
        width: 80px;
      }
    }
    .cont {
      font-size: 16px;
      color: #f5f5f5;
      line-height: 32px;
      text-align: center;
      margin: 28px auto 60px;
      @media @max750 {
        font-size: 14px;
        line-height: 24px;
        margin: 18px 12px 0;
      }
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      @media @max750 {
        padding: 0 12px;
        flex-wrap: wrap;
        align-items: initial;
        justify-content: center;
      }
      .item {
        display: flex;
        padding: 0 70px;
        position: relative;
        align-items: center;
        @media @max750 {
          padding: 0;
          width: 33.3%;
          margin-top: 20px;
          .flex-center;
          &:first-child {
            padding-left: 0;
          }
          &:nth-child(3) {
            &::after {
              display: none;
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
        &::after {
          content: '';
          top: 50%;
          right: 0;
          width: 1px;
          height: 16px;
          position: absolute;
          background: #474b4d;
          transform: translateY(-50%);
          @media @max750 {
            height: 26px;
          }
        }
        &:last-of-type::after {
          opacity: 0;
        }
        img {
          .wh(26px, auto);
        }
        .info {
          margin-left: 6px;
          @media @max750 {
            margin-left: 4px;
          }
          p {
            font-size: 14px;
            color: #d9d9d9;
            white-space: nowrap;
          }
          h4 {
            color: #fff;
            font-size: 16px;
            padding-left: 1px;
            @media @max750 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .footer_bottom {
    height: 100px;
    display: flex;
    padding: 0 60px;
    align-items: center;
    background: #0d1214;
    justify-content: space-between;
    @media @max750 {
      height: 120px;
      padding: 0 12px;
      justify-content: center;
    }
    .logo2{
      width: 95px;
      img {
        width: 100%;
        height: auto;
      }
     
    }
    .logo {
      width: 231px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .cont {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      @media @max750 {
        align-items: center;
      }
      .menu {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media @max750 {
          flex-wrap: wrap;
          align-items: initial;
          justify-content: center;
        }
        .item {
          .flex-a-center;
          padding: 0 14px;
          color: #bfbfbf;
          position: relative;
          text-align: center;
          white-space: nowrap;
          box-sizing: border-box;
          @media @max750 {
            padding: 0;
            width: 30%;
            display: flex;
            justify-content: center;
            &:first-child {
              padding-left: 0;
            }
            &:nth-child(3) {
              &::after {
                display: none;
              }
            }
            &:last-child {
              padding-right: 0;
            }
          }
          &:hover {
            color: #fff;
          }
          i {
            font-size: 18px;
            @media @max750 {
              font-size: 14px;
            }
          }
          span {
            font-size: 16px;
            padding-left: 2px;
            @media @max750 {
              font-size: 14px;
              padding-left: 1px;
              text-align: center;
            }
          }
          &::after {
            content: '';
            top: 50%;
            right: 0;
            width: 1px;
            height: 12px;
            position: absolute;
            background: #dbdbdb;
            transform: translateY(-50%);
          }
          &:last-of-type {
            padding-right: 0;
            &::after {
              opacity: 0;
            }
          }
        }
      }
      .info {
        display: flex;
        margin-top: 6px;
        align-items: center;
        img {
          width: 16px;
          height: auto;
          padding-right: 2px;
        }
        span {
          font-size: 16px;
          color: #bfbfbf;
          .flex-a-center;
          &:last-child {
            padding-left: 30px;
          }
        }
        @media @max750 {
          flex-direction: column;
          img {
            width: 14px;
          }
          span {
            font-size: 14px;
            &:last-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
