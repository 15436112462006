import axios from 'axios';
import { getToken, removeToken } from './cookie';
import { Message } from 'element-ui';
import store from '@/vuex/store';
// baseURL: "http://192.168.1.3:8081",
  // baseURL: "http://192.168.1.3:8080",
  // baseURL: "http://123.60.170.219:8082",
  // baseURL: "http://localhost:8082",
  // baseURL: "http://192.168.1.39:8082",
  //  baseURL: "http://beecsgo.com/prod-api/",
  //  baseURL: "http://test.csgo800.com/prod-api/",
  //  baseURL: "http://www.mkcsgo.com/prod-api/",
  // baseURL: 'http://127.0.0.1/prod-api',
  // baseURL: "http://47.113.194.138:80/prod-api/",
  // baseURL: "http://47.113.194.138:8085/prod-api/",
  // baseURL: 'http://123.60.170.219:8086/prod-api/',
  // baseURL: 'http://123.60.170.219:8082/prod-api/',
const reqAxios = axios.create({
  
  // baseURL: 'http://api.beecsgo.com/prod-api',
  baseURL: 'http://api.500csgo.com/prod-api',
  // baseURL: 'http://192.168.1.11:8082',
  timeout: 60000, // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
});
// 拦截请求（拦截器）
const timer = new Map();
reqAxios.interceptors.request.use(
  function (request) {
    // 处理请求公共数据，如添加请求头令牌...
    let token = getToken();
    //请求头设置
    request.headers = {
      'content-type': 'application/json;',
      userType: 'user',
      Authorization: token,
    };
    if (request.url == '/api/luckyUpgrade/upgrade') {
      return throttle(request, 3500);
    }
    if (
      request.url == '/api/luckyUpgrade/getUpgradeRecord' ||
      request.url == '/api/roll/getRollList' ||
      request.url == '/api/userAmountRecords/blendErcashRank' ||
      request.url == '/api/boxRecords/historyByCondition' ||
      request.url == '/api/userAmountRecords/deliveryRecords' ||
      request.url == '/api/userAmountRecords/userAccountDetail' ||
      request.url == '/api/userPackSack/getPackSack' ||
      request.url == '/api/roll/getRollPlayers'
    ) {
      return request;
    }
    // return throttle(request, 200);
    return request;
  },
  function (error) {
    console.log('request-error', error);
    return Promise.reject(error);
  },
);
function throttle(request, time) {
  // console.log(request);
  let lastTime = timer.get(request.url);
  let nowTime = new Date().getTime();
  if (request.method == 'get') {
    return request;
  } else {
    if (lastTime && nowTime - lastTime < time) {
      console.log('overRequest', request);
      return Promise.reject('请勿频繁操作');
      // return false
    } else {
      timer.set(request.url, nowTime);
      return request;
    }
  }
}

//响应拦截器
reqAxios.interceptors.response.use(
  res => {
    if (res?.data?.code == 401) {
      // Message({
      //   message: '登录已过期,请重新登陆',
      //   type: 'warning',
      //
      // });
      removeToken();
      store.commit('LOGIN_IS_SHOW', true);
      return;
    } else if (res.status == 200) {
      return res;
    } else if (res?.data?.code == 200) {
      return res;
    } else {
      Message({ message: res.data.msg, type: 'warning' });
      return res;
    }
  },
  err => {
    console.log('err', err);
    if (err == '请勿频繁操作') {
      Message({ message: '您的操作太频繁，请1秒后再试', type: 'warning' });
      // 数据健壮性
      return { data: { code: '', msg: '', data: {} } };
    }
    if (err.response?.status == 500) {
      Message({ message: err.response.data.message, type: 'warning' });
    }
    if (err.response?.status == 401) {
      removeToken();
      store.commit('LOGIN_IS_SHOW', true);
      // Message({
      //   message: '登录过期，请重新登陆',
      //   type: 'warning',
      //
      // });
    }
  },
);

export default reqAxios;
