<template>
  <div :class="title=='福利中心'?'header_top newTop':'header_top'">
    <slot></slot>
   <div> {{ title }}</div>
    <span v-if="readme" class="readme" @click="handleReadme">
      <i class="el-icon-warning-outline"></i>
      <span>{{ readme }}</span>
    </span>
    <img v-if="title=='福利中心'" class="infoImg" src="../../assets/image/info.png" alt="" @click="handleReadme">
  </div>
</template>

<script>
export default {
  props: ['title', 'readme'],
  methods: {
    handleReadme() {
      this.$emit('handleReadme');
    },
  },
};
</script>

<style lang="less" scoped>
.newTop{
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoImg{
  width: 25px;
  height: 25px;
  transform: translateX(20px);
}
.header_top {
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  // display: flex;
  // justify-content: space-between;
  .sc(22px, #fff);
  @media @max750 {
    height: 56px;
    font-size: 18px;
    line-height: 56px;
  }
  .readme {
    .abs-ct;
    .flex-a-center;
    .sc(14px, #888);
    right: 0;
    @media @max750 {
      font-size: 12px;
    }
    i {
      font-size: 18px;
      @media @max750 {
        font-size: 14px;
      }
    }
    span {
      padding-left: 4px;
    }
  }
}
</style>
