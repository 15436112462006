<template>
  <div class="home">
    <!-- <swiper key="index" /> -->
    <banner />
    <template v-if="$store.state.mode == 'pc'">
      <div class="roll_list" v-if="rollList.length">
        <rollCard v-for="(item, index) in rollList" :rollItem="item" :key="item.gameId" :index="index + 1" @refresh="queryRollList" />
      </div>
      
      <div class="arena_wrap">
        <div class="arena_title">
          <div class="title">
            <img src="@/assets/image/jingji.png" alt="" />
            <span>竞技场</span>
          </div>
          <div class="arena_btn pc-show">
            <button :class="['btn', { active: arenaStatus == item.status }]" v-for="item in arenaList" :key="item.status" @click="clickArenaBtn(item)">
              <i :class="item.icon"></i>
              <span>{{ item.btn }}</span>
            </button>
          </div>
        </div>
        <div class="arena_list">
          <arenaCard v-for="item in fightList" :key="item.id" :data="item" @clickBtn="handleArenaBtn" />
          <div class="more" @click="toPath('/pk')">
            <i class="icon el-icon-circle-plus"></i>
            <span class="text">查看更多 >></span>
          </div>
        </div>
      </div>
      <blindBox title="500的盲盒" :list="boxList" />
      <blindBox title="玩家的盲盒" :list="playerBoxList" />
    </template>
    <template v-else>
      <div class="classify_box">
        <div class="classify_left" @click="toPath('/open')">
          <img class="bg" src="../assets/image/index/mhbg.png" alt="" />
          <img class="img" src="../assets/image/index/mh.png" alt="" />
          <p class="text">开启盲盒之旅</p>
        </div>
        <div class="classify_right">
          <div class="pk" @click="toPath('/pk')">
            <img class="bg" src="../assets/image/index/jjcbg.png" alt="" />
            <img class="img" src="../assets/image/index/jjc.png" alt="" />
            <p class="text">竞技场</p>
          </div>
          <div class="dream" @click="toPath('/dream')">
            <img class="bg" src="../assets/image/index/zmbg.png" alt="" />
            <img class="img" src="../assets/image/index/zm.png" alt="" />
            <p class="text">追梦</p>
          </div>
        </div>
      </div>
      <div class="menu_top">
        <div class="custom">
          <img class="left" src="../assets/image/index/zdymh.png" alt="" />
          <img class="right" @click="toPath('/open?tab=4')" src="../assets/image/index/cjmh.png" alt="" />
        </div>
        <div class="menu_list">
          <div class="menu_item" v-for="(item, index) in menusTop" :key="index" @click="toPath(item.url)">
            <img class="img" :src="require(`../assets/svgs/${item.icon}.svg`)" alt="" />
            <p class="name">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="menu_bottom">
        <div class="menu_item" v-for="(item, index) in menusBottom" :key="index" @click="toPath(item.url)">
          <img class="img" :src="require(`../assets/svgs/${item.icon}.svg`)" alt="" />
          <p class="name">{{ item.name }}</p>
        </div>
      </div>
      <div class="app_type">
        <div class="type" @click="downloadIos">
          <img class="img" src="../assets/svgs/ios.svg" alt="" />
          <span class="name">苹果</span>
        </div>
        <div class="type" @click="downloadAnd">
          <img class="img" src="../assets/svgs/android.svg" alt="" />
          <span class="name">安卓</span>
        </div>
      </div>
      <div class="roll_list" v-if="rollList.length">
        <rollCard v-for="(item, index) in rollList" :rollItem="item" :key="item.gameId" :index="index + 1" @refresh="queryRollList" />
      </div>
    </template>
  </div>
</template>

<script>
// import swiper from '@/components/swiperUser.vue';
import banner from '@/components/bannerUser.vue';
import blindBox from '@/components/blindBox.vue';
import rollCard from '@/components/rollCard.vue';
import arenaCard from '@/components/arenaCard.vue';
import { Message } from 'element-ui';

import {
  getUserInfo,
  getBoxList,
  // getFightList, fightOnMyOwn,
  getRollList,
  highLight,
} from '@/api/index';

export default {
  data() {
    return {
      arenaStatus: 4,
      arenaList: [
        // { btn: '进行中', icon: 'el-icon-scissors', status: 1, params: [0, 1] },
        // { btn: '已结束', icon: 'el-icon-switch-button', status: 2, params: [2] },
        // { btn: '参与过', icon: 'el-icon-finished', status: 3, params: [3] },
        { btn: '高光时刻', icon: 'el-icon-trophy', status: 4, params: [3] },
      ],
      menusTop: [
        { name: '盲盒充值', icon: 'recharge', url: '/recharge' },
        { name: '我的盲盒', icon: 'my', url: '/open?tab=3' },
        { name: '玩家盲盒', icon: 'mh', url: '/open?tab=2' },
        { name: 'ROLL', icon: 'roll', url: '/roll' },
      ],
      menusBottom: [
        { name: '推广中心', icon: 'tgzx', url: '/home?type=7' },
        { name: '客服', icon: 'kf', url: 'https://qm.qq.com/q/Yj7En8LdyG' },
        { name: '饰品商城', icon: 'spsc', url: '/shopping' },
        { name: '会员福利', icon: 'fuli', url: '/hongbao' },
        // { name: '推广红包', icon: 'hbfl', url: '/CDK?tab=1' },
      ],
      boxList: [],
      playerBoxList: [],
      rollList: [],
      fightList: [],
    };
  },
  components: { banner, blindBox, arenaCard, rollCard },
  created() {
  console.log(12364);
  
    this.queryBoxList();
    this.queryRollList();
    // this.queryFightList({ status: 1, params: [0, 1] });
    this.queryFightList({ status: 1, params: [0, 1] });
  },
  methods: {
    queryBoxList() {
      const params = {
        boxTypeId: '',
        isPlayerBox: false,
        boxTypeName: '盲盒类别',
        gameMouldId: 1,
        page: 1,
        size: 10,
        orderByFie: 2,
      };
      const params2 = {
        boxTypeId: '',
        isPlayerBox: true,
        boxTypeName: '盲盒类别',
        gameMouldId: 1,
        page: 1,
        size: 10,
        orderByFie: 2,
      };
      getBoxList(params).then(res => {
        if (res?.data?.code != 200) return;
        this.boxList = res?.data?.data?.list || [];
      });
      getBoxList(params2).then(res => {
        if (res?.data?.code != 200) return;
        this.playerBoxList = res?.data?.data?.list || [];
      });
    },
    queryRollList() {
      getRollList({ page: 1, size: 4, gameStatus: 0, rollCycleType: 2 }).then(res => {
        const { list } = res?.data?.data || {};
        this.rollList = list || [];
        if(this.rollList.length<4){
          let i=4-this.rollList.length
          getRollList({ page: 1, size: i, gameStatus: 0, rollCycleType: 1, orderField:2,rollType:0}).then(res=>{
            const { list } = res?.data?.data || {};
            this.rollList.push(...list)
          })
        }
      });
    },
    async queryFightList() {
      // async queryFightList({ status, params }) {
      // const size = 3;
      let res=await highLight({page:1,size:3})
      // this.arenaStatus = status || 1;
      // const playerId = this.$store.state.USER_INFO.userId;
      // const params1 = { playerId, page: 1, size, statusList: [0, 1, 2, 3] };
      // const params2 = { page: 1, size, statusList: params };
      // let res = status == 3 ? await fightOnMyOwn(params1) : await getFightList(params2);
      let arr = res.data?.data?.list || [];
      arr.map(item => {
        let obj = JSON.parse(item.boxData);
        let valueList = [];
        for (let i in obj) valueList.push(obj[i]);
        return (item.boxData = valueList);
      });
      this.fightList = [...arr];
    },
    handleArenaBtn({ id }) {
      this.playAudio();
      getUserInfo().then(res => {
        if (res?.data?.code == 200) {
          const uid = res?.data?.data?.userId;
          this.$router.push({ name: 'roomDetail', query: { id, uid } });
        } else {
          this.$router.push('/login');
        }
      });
    },
    toPath(url) {
      this.playAudio();
      if (url == 'https://qm.qq.com/q/Yj7En8LdyG') {
        window.location.href = url;
        return;
      }
      this.$router.push(url);
    },
    clickArenaBtn(item) {
      this.playAudio();
      this.queryFightList(item);
    },
    downloadAnd() {
      this.playAudio();
      window.open("http://www.500csgo.com/download/500csgo.apk");
    },
    downloadIos() {
      this.playAudio();
      Message({ message: '敬请期待', type: 'warning' });
      // window.open('http://www.mkcsgo.com/download/MKCSGOAPPLE.mobileconfig');
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
  },
};
</script>

<style scoped lang="less">
.home {
  .blind_box:last-of-type {
    margin-bottom: 0;
  }
  .roll_list {
    .grid(4, 20px);
    margin: 40px @pcSpace;
  }
  .arena_wrap {
    padding: 40px 0;
    margin: 0 @pcSpace;
    border-top: 1px solid @bd1;
    border-bottom: 1px solid @bd1;
    .arena_title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        white-space: nowrap;
        img {
          width: 40px;
          height: auto;
        }
        span {
          color: #fff;
          font-size: 36px;
          padding-left: 10px;
        }
      }
      .arena_btn {
        margin: 0 !important;
        .btn {
          width: 140px;
          height: 40px;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
          box-shadow: none;
          overflow: hidden;
          line-height: 40px;
          border-radius: 20px;
          background: @bg3;
          margin-left: 16px;
          border: 1.5px solid @bd2;
          span {
            padding-left: 6px;
          }
        }
        .active {
          width: 151px;
          height: 51px;
          border: none;
          background: url(../assets/image/loginbtn.png) no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }
    }
    .arena_list {
      .grid(4, 14px);
      .more {
        display: flex;
        min-height: 286px;
        border-radius: 12px;
        align-items: center;
        background: @bg2;
        flex-direction: column;
        justify-content: center;
        .icon {
          font-size: 66px;
          color: @main;
          cursor: pointer;
          border-radius: 50%;
          border: 15px solid #205f64;
          box-shadow: 0 0 40px 10px #316164;
        }
        .text {
          color: #fff;
          font-size: 20px;
          padding-top: 20px;
        }
      }
    }
  }
  @media @max750 {
    overflow: hidden;
    background: url(../assets/image/index/bg.png) no-repeat;
    background-size: 100% 100%;
    .home_banner {
      margin-left: @mSpace;
      margin-right: @mSpace;
    }
    .classify_box {
      .grid(2, 10px);
      margin: 28px @mSpace 0;
      .classify_left {
        width: 100%;
        position: relative;
        .bg {
          .wh(100%, auto);
        }
        .img {
          right: 0;
          top: -16px;
          position: absolute;
          .wh(96%, auto);
        }
        .text {
          left: 14px;
          bottom: 16px;
          font-weight: bold;
          padding-bottom: 4px;
          position: absolute;
          .sc(20px, #0d0c0f);
          &::after {
            content: '';
            left: 0;
            bottom: 0;
            .wh(50%, 1px);
            position: absolute;
            background: #0d0c0f;
          }
        }
      }
      .classify_right {
        .flex-column;
        justify-content: space-between;
        & > div {
          width: 100%;
          position: relative;
          .bg {
            .wh(100%, auto);
          }
          .img {
            left: 0;
            top: -6px;
            position: absolute;
            .wh(55%, auto);
          }
          .text {
            .abs-ct;
            left: 53%;
            font-weight: bold;
            position: absolute;
            .sc(18px, @main);
          }
          &.dream .img {
            top: -12px;
          }
        }
      }
    }
    .menu_top {
      height: 163px;
      position: relative;
      margin: 40px @mSpace 0;
      background: url(../assets/image/index/menubg.png) no-repeat;
      background-size: 100% 100%;
      .custom {
        left: 0;
        top: -24px;
        width: 100%;
        position: absolute;
        justify-content: space-between;
        .flex-a-center;
        .left {
          .wh(58.2%, auto);
        }
        .right {
          .wh(37.3%, auto);
          margin-top: 10px;
          margin-right: 12px;
        }
      }
      .menu_list {
        display: flex;
        padding-top: 88px;
        justify-content: space-around;
        .menu_item {
          .flex-column-center;
          .img {
            .wh(28px, 28px);
          }
          .name {
            margin-top: 10px;
            .sc(12px, #fff);
          }
        }
      }
    }
    .menu_bottom {
      .grid(4, 10px);
      margin: 14px @mSpace;
      .menu_item {
        .flex-column-center;
        padding: 12px 0 10px;
        background: #1d1f27;
        border: 1px solid #000000;
        .img {
          .wh(28px, 28px);
        }
        .name {
          margin-top: 12px;
          .sc(12px, #fff);
        }
      }
    }
    .app_type {
      .grid(2, 10px);
      margin: 14px @mSpace;
      .type {
        .flex-center;
        padding: 10px 0;
        background: #1d1f27;
        border: 1px solid #000000;
        .img {
          .wh(24px, 24px);
        }
        .name {
          .sc(12px, #fff);
          padding-left: 6px;
        }
      }
    }
    .roll_list {
      .grid(2, 10px);
      margin: 20px @mSpace;
    }
  }
}
</style>
