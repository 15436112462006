<template>
  <header class="header">
    <div class="header_left" @click="toPath('/')">
      <img src="@/assets/image/wlogo3.png" class="logo" alt />
    </div>
    <div class="header-center">
      <nav :class="['menu-item', { checked: item.urls.includes($route.path) }]" v-for="(item, index) in headerMenu" :key="index" @click="toPath(item.urls[0])">
        <div v-if="item.title != 'APP'">
          <i :class="['icon', item.icon]" ></i>
          <span class="title" >{{ item.title }}</span>
        </div>
        <div v-else>
          <el-dropdown class="pc-show" @command="handleDownLoad" trigger="click" placement="bottom">
            <div class="header_user" @click="playAudio">
              <i :class="['icon', item.icon]" style="color: white;"></i>
              <span class="title" style="color: white;">{{ item.title }}</span>
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdown_menu2">
              <el-dropdown-item class="dropdown_item2" v-for="item in downLoadMenu" :key="item.id" :command="item.id">
                <!-- <i :class="['icon', item.icon]"></i> -->
                <span class="name">{{ item.title }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </nav>
    </div>
    <div class="header_right">
      <!-- 未登录显示 -->
      <div v-if="LOGIN_IS_SHOW" class="login" @click="toPath('/login')">登录注册</div>
      <!-- 登录成功显示 -->
      <div v-else class="header_right_info">
        <!-- 头像 -->
        <img class="img mobile-show" v-if="!showClose" :src="userInfo.avatar" alt="" @click="toPath('/home')" />
        <el-dropdown class="pc-show" @command="handleCommand" trigger="click">
          <div class="header_user" @click="playAudio">
            <img class="img" :src="userInfo.avatar" alt />
            <span class="name pc-show">{{ userInfo.nickName }}</span>
            <i class="el-icon-arrow-down pc-show"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown_menu">
            <el-dropdown-item class="recharge" :command="9"><span>充值</span></el-dropdown-item>
            <el-dropdown-item class="dropdown_item" v-for="item in userMenu" :key="item.id" :command="item.id">
              <i :class="['icon', item.icon]"></i>
              <span class="name">{{ item.title }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 金币 -->
        <div v-if="showClose" class="mobile-show">
          <div class="header_more" @click="handleClose">
            <i class="icon el-icon-close"></i>
          </div>
        </div>
        <div class="header_gold" v-else>
          <price :price="userInfo.accountAmount" />
          <price :price="userInfo.accountCredits" :type="2" />
        </div>
        <!-- 通知 -->
        <div class="header_notice pc-show" @click="toPath('/notifications')">
          <i class="el-icon-message-solid"></i>
          <span class="dot" v-if="$store.state.noReadMsg"></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { getUserInfo, logOut } from '@/api/index';
import { removeToken } from '@/api/cookie';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      userInfo: {},
      isOpen: false,
      headerMenu: [
        { title: '首页', icon: 'el-icon-s-home', urls: ['/'] },
        { title: '幸运盲盒', icon: 'el-icon-s-cooperation', urls: ['/open', '/openbox'] },
        { title: '竞技场', icon: 'el-icon-s-flag', urls: ['/pk', '/creatroom', '/roomDetail'] },
        { title: 'ROLL房', icon: 'el-icon-s-help', urls: ['/roll', '/rollDetail'] },
        { title: '%追梦', icon: 'el-icon-s-opportunity', urls: ['/dream'] },
        { title: '商城', icon: 'el-icon-s-shop', urls: ['/shopping'] },
        { title: '会员福利', icon: 'el-icon-s-goods', urls: ['/hongbao'] },
        { title: 'APP', icon: 'el-icon-mobile', urls: ['APP'] },
      ],
      downLoadMenu: [{ title: '安卓下载', icon: '', id: 1 }],
      userMenu: [
        { title: '个人资料', icon: 'el-icon-user-solid', id: 1 },
        { title: '我的背包', icon: 'el-icon-s-cooperation', id: 2 },
        { title: '提货信息', icon: 'el-icon-s-order', id: 4 },
        { title: '会员福利', icon: 'el-icon-star-on', id: 6 },
        { title: '推广会员', icon: 'el-icon-share', id: 7 },
        // { title: '推广红包', icon: 'el-icon-message', id: 10 },
        { title: '充值明细', icon: 'el-icon-s-claim', id: 5 },
        { title: '退出登录', icon: 'el-icon-error', id: 8 },
      ],
    };
  },
  computed: {
    ...mapState(['LOGIN_IS_SHOW', 'USER_INFO']),
    showClose() {
      if (this.$store.state.mode == 'pc') return false;
      if (this.$route.path != '/home') return false;
      // 会员中心、推广中心要展示金币，不展示关闭按钮
      const { type } = this.$route.query;
      if ([6, 7].includes(+type)) return false;
      return true;
    },
  },
  watch: {
    USER_INFO(val) {
      this.userInfo = val;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    toPath(url) {
      if (url == 'APP') {
        return;
      }
      this.$router.push(url);
      this.playAudio();
    },
    handleClose() {
      this.playAudio();
      if (this.$route.path == '/home') {
        this.$router.back();
      }
    },
    handleDownLoad(){
      // console.log(1111);
      window.open("http://www.500csgo.com/download/500csgo.apk");
    },
    handleCommand(id) {
      this.playAudio();
      if (id == 8) {
        this.$confirm('确定要退出登录吗?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          cancelButtonClass: 'active_btn',
          center: true,
          // beforeClose: (action, instance, done) => {
          //   this.handleLoginOut(done);
          // },
        })
          .then(() => {
            this.handleLoginOut()
          })
          .catch(() => {
            this.playAudio();
          });
      } else if (id == 9) {
        this.$router.push('/recharge');
      } else if (id == 6) {
        this.$router.push('/hongbao');
      }else if(id==10){
        this.$router.push('/CDK?tab=1');
      } else {
        this.$router.push(`/home?type=${id}`);
      }
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleLoginOut() {
      this.playAudio();
      logOut().then(res => {
        if (res?.data?.code != 200) return;
        // done();
        removeToken();
        this.$store.commit('LOGIN_IS_SHOW', true);
        this.$store.commit('closeWs');
        this.$store.commit('logOut');
        this.$router.go(0);
        this.$message({
              offset: 50,
              type: 'success',
              message: '已退出',
            });
      });
    },
    getInfo() {
      getUserInfo().then(res => {
        if (res?.data?.code == 200) {
          this.userInfo = res?.data?.data || {};
          this.$store.commit('USER_INFO', res?.data?.data);
        } else {
          this.$store.commit('LOGIN_IS_SHOW', true);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 80px;
  display: flex;
  padding: 0 60px;
  position: sticky;
  align-items: center;
  background: #0d1214;
  justify-content: space-between;
  @media @max750 {
    height: 60px;
    padding: 0 @mSpace;
  }
  .header_left {
    width: 85px;
    .logo {
      width: 100%;
      height: auto;
    }
    @media @max750 {
      width: 40px;
    }
  }
  .header-center {
    flex: 1;
    display: flex;
    margin: 0 20px;
    @media @max750 {
      display: none;
    }
    .menu-item {
      flex: 1;
      color: #fff;
      height: 40px;
      display: flex;
      margin: 0 10px;
      padding: 0 10px;
      font-size: 16px;
      line-height: 40px;
      align-items: center;
      border-radius: 24px;
      justify-content: center;
      border: 2px solid transparent;
      .flex-center;
      .title {
        padding-left: 6px;
        white-space: nowrap;
      }
      &:hover {
        color: @main;
      }
    }
    .checked {
      color: @main;
      border-color: @main;
    }
  }
  .login {
    width: 170px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: url(../assets/image/loginbtn.png) no-repeat;
    background-size: 100% 100%;
    .sc(16px, #fff);
    font-weight: 500;
    @media @max750 {
      width: 130px;
      height: 40px;
      line-height: 40px;
    }
  }
  .header_right_info {
    display: flex;
    align-items: center;
    .header_user {
      height: 44px;
      display: flex;
      padding: 0 10px;
      align-items: center;
      border-radius: 30px;
      background: #1a1f21;
      justify-content: center;
      .name {
        color: #ededed;
        font-size: 16px;
        max-width: 110px;
        overflow: hidden;
        padding: 0 4px 0 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .img {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      @media @max750 {
        width: 40px;
        height: 40px;
        max-width: 54px;
      }
    }
    .header_more {
      .wh(40px, 40px);
      border-radius: 50%;
      background: @bg1;
      .flex-center;
      .icon {
        .sc(24px, @main);
        transform: rotate(90deg);
      }
    }
    .header_gold {
      display: flex;
      margin-left: 10px;
      flex-direction: column;
      justify-content: space-between;
    }
    .header_notice {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      position: relative;
      line-height: 42px;
      text-align: center;
      border-radius: 50%;
      background: #272b2e;
      i {
        font-size: 20px;
      }
      .dot {
        top: 10px;
        right: 10px;
        width: 6px;
        height: 6px;
        position: absolute;
        border-radius: 50%;
        background: red;
      }
    }
  }
}
.dropdown_menu {
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  background: #0d1214;
  &.menu_list {
    border: 1px solid @bg2;
    .dropdown_item {
      min-width: 100px;
    }
    .checked {
      color: @main;
    }
  }
  .recharge {
    padding: 12px 16px;
    border-bottom: 1px solid @bg2;
    span {
      width: 127px;
      height: 42px;
      color: #fff;
      display: block;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      background: url(../assets/image/loginbtn.png) no-repeat;
      background-size: 100% 100%;
    }
    &:hover {
      color: #fff;
      background: none;
    }
  }
  .dropdown_item {
    height: 40px;
    padding: 0 10px;
    line-height: 40px;
    .sc(14px, #ddd);
    &:hover {
      color: @main;
      background: #1a1f21;
    }
    &:last-of-type {
      border-top: 1px solid @bg2;
    }
  }
}
.dropdown_menu2 {
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  background: #0d1214;
  &.menu_list {
    border: 1px solid @bg2;
    .dropdown_item2 {
      min-width: 100px;
      .name{
        // font-size: 16px;

      }
    }
    .checked {
      color: @main;
    }
  }
  .recharge {
    padding: 12px 16px;
    border-bottom: 1px solid @bg2;
    span {
      width: 127px;
      height: 42px;
      color: #fff;
      display: block;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      background: url(../assets/image/loginbtn.png) no-repeat;
      background-size: 100% 100%;
    }
    &:hover {
      color: #fff;
      background: none;
    }
  }
  .dropdown_item2 {
    height: 40px;
    padding:  10px 20px;
    line-height: 40px;
    .sc(16px, #ddd);
    &:hover {
      color: @main;
      background: #1a1f21;
    }
    &:last-of-type {
      border-top: 1px solid @bg2;
    }
  }
}
</style>

<style>
.dropdown_menu.el-popper[x-placement^='bottom'] .popper__arrow,
.dropdown_menu.el-popper[x-placement^='bottom'] .popper__arrow::after {
  border: none;
}
</style>
