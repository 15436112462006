import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/vuex/store';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/font/iconfont.css';
import 'animate.css';

import '@/assets/style/global.less';
import '@/assets/style/success.less';
import money from '@/components/common/money.vue';
import price from '@/components/common/price.vue';
import alert from '@/components/common/alert.vue';
import boxDetail from './components/boxDetail.vue';
import headerTop from '@/components/common/header.vue';
import illustrate from '@/components/illustrate.vue';
import VueAgile from 'vue-agile';
import Video from 'video.js';
import 'video.js/dist/video-js.css';
import toastRegistry from './views/toast/index';
import VueClipboard from 'vue-clipboard2';
import { Button } from 'vant';
import { DatetimePicker } from 'vant';
import { Field } from 'vant';
import { Popup } from 'vant';


Vue.prototype.$video = Video;
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(Popup);

Vue.use(Button);
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.prototype.$main = '#00F9FF';
Vue.component('money', money);
Vue.component('boxDetail', boxDetail);
Vue.component('price', price);
Vue.component('alert', alert);
Vue.component('headerTop', headerTop);
Vue.component('illustrate', illustrate);
Vue.config.productionTip = false;
Vue.use(VueAgile);
Vue.use(toastRegistry);
import Router from 'vue-router';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: h => h(App),
}).$mount('#app');
