<template>
  <div class="boxDetailBody">
    <div class="close" @click="close">返回</div>
    <div class="title">箱子详情</div>
    <div class="boxName">名称：{{ boxName }}</div>
    <div class="boxPrice">
        <price :price="boxPrice" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
    </div>
    <!-- <div class="levelBox">
        <div class="level">
          <div class="levelItem" v-for="(item, i) in probabilityList" :key="i">
            <div
              class="color"
              :style="{ backgroundColor: backgroundColor(item.level) }"
            ></div>
            {{ item.probability }}</div
          >
        </div>
      </div> -->
    <div class="itemList">
        <goodsCard v-for="(item, index) in list" :key="index" :goodsItem="item"  :keyMap="{ price: 'staticPrice' }"  />
    </div>
  </div>
</template>

<script>
import { getDetail } from '@/api/index';
import goodsCard from '@/components/goodsCard';

export default {
  props: ['showDetail', 'boxId'],
  components: { goodsCard },
  data() {
    return {
      list: [],
      boxName: null,
      probabilityList: [],
      boxPrice: null,
    };
  },
  methods: {
    close(){
        this.showDetail.showDetail=false
    },
    backgroundColor(level) {
      if (level == "金色") {
        return "#f9c328";
      } else if (level == "红色") {
        return "#e43e30";
      } else if (level == "紫色") {
        // return "#460f8d";
        return "#620b9a";
      } else if (level == "蓝色") {
        return "#0a86d9";
      } else if (level == "灰色") {
        return "#807d7d";
      }
    },
  },
  computed: {},
  filters: {},
  watch: {},
  mounted() {
    getDetail(this.boxId).then(res => {
      this.list = res.data.data.boxOrnamentsList;

      this.probabilityList = res.data.data.probabilityDistribution.sort((a, b) => {
        return a.ornamentsLevelId - b.ornamentsLevelId;
      });
      this.boxName = res.data.data.boxName;
      this.boxPrice = res.data.data.price;
    });
  },
};
</script>

<style lang="less" scoped>
.boxDetailBody {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.726);
  box-sizing: border-box;
  padding: 300px 300px 0;
  @media @max750 {
    padding: 50px 20px 0;
  }
  .title {
    .sc(30px, #fff);
    font-weight: bold;
    text-align: center;
  }
  .close {
    .sc(20px, #fff);
    text-align: right;
  }
  .boxName{
    text-align: center;
    .sc(20px, #fff);
    padding: 30px 0 10px;
  }
  .boxPrice{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    
  }
  .itemList{
    .grid(7, 10px);
    @media @max750 {
        .grid(3, 5px);
  }
  }
}
.levelBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .level {
    display: flex;
    color: white;
    flex-wrap: wrap;
    // position: absolute;
    // top: 1024rem;
    // left: 48rem;
    // margin: 0 auto;
    max-width: 100%;
    font-size: 16px;
    .levelItem {
      margin-right: 20px;
      position: relative;
      padding-left: 40px;
    }
    .color {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 12px;
    }
  }
}
</style>
