<template>
  <div id="app" class="app">
    <GlobalHeader />
    <main id="main">
      <div id="container">
        <router-view v-if="!$route.meta.iskeep"></router-view>
        <keep-alive>
          <router-view v-if="$route.meta.iskeep"></router-view>
        </keep-alive>
      </div>
      <GlobalFooter id="globalFooter" />
    </main>
    <bottomUser v-if="$store.state.mode == 'mobile'" />
    <illustrate :visible="isillustrate" />
  </div>
</template>

<script>
import { getToken } from '@/api/cookie';
import { setRem } from '@/utils/rem';
import GlobalHeader from '@/components/GlobalHeader.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import bottomUser from '@/components/bottomUser.vue';
import { getUserInfo, getMessageList, getRechargeGreadeNorm, gameConditionById } from '@/api/index';
export default {
  data() {
    return {
      isillustrate: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      currentGrade: 0,
      nextGameCondition: {},
      levelPage: {
        page: 1,
        size: 20,
      },
      levelList: [],
    };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
    bottomUser,
  },
  created() {
    setRem();
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit('playAudio');
    });

    this.getToken();
    this.$bus.$on('is_illustrate', data => {
      this.isillustrate = data;
    });
    this.queryMessageList();
    this.getLevelList();
  },
  methods: {
    findLevel(i) {
      if (this.levelList.length == i) {
        this.nextGameCondition.rulerRecharge = 'max';
        // this.$store.commit('setLevel', this.currentGrade, this.nextGameCondition);
        this.$store.commit('setLevel', {currentGrade:this.currentGrade, nextGameCondition:this.nextGameCondition});
        return false;
      }
      gameConditionById(this.levelList[i].gameConditionId).then(res => {
        if (this.userInfo.gradeConsumeValue >= res?.data?.data.rulerConsumeBlend && this.userInfo.gradeRechargeValue >= res?.data?.data.rulerRecharge) {
          this.currentGrade = i;
          // console.log('符合', i);

          this.findLevel(i + 1);
        } else {
          // console.log('不符合', i);

          this.nextGameCondition = res?.data?.data;
          this.$store.commit('setLevel', {currentGrade:this.currentGrade, nextGameCondition:this.nextGameCondition});
        }
      });
    },
    getLevelList() {
      getRechargeGreadeNorm(this.levelPage).then(res => {
        this.levelList.push(...res.data.data.list);
        this.levelList = this.levelList.sort((a, b) => {
          return a.grade - b.grade;
        });
        console.log('充值等级', this.levelList);
        if (res.data.data.list.length == this.levelPage.size) {
          this.levelPage.page++;
          this.getLevelList();
        } else {
          getUserInfo().then(res => {
            if (res?.data?.code == 200) {
              this.userInfo = res?.data?.data || {};
              this.$store.commit('USER_INFO', res?.data?.data);
              this.findLevel(0);
            } else {
              this.$store.commit('LOGIN_IS_SHOW', true);
            }
          });
        }
      });
    },
    queryMessageList() {
      getMessageList(this.page).then(({ data }) => {
        if (data?.data?.data?.list) {
          if (data.data.notRead != 0) {
            this.$store.commit('changeMsg', true);
          } else {
            this.$store.commit('changeMsg', false);
          }
        }
        setTimeout(() => {
          this.queryMessageList();
        }, 5000);
      });
    },
    getToken() {
      if (getToken()) {
        this.$store.commit('LOGIN_IS_SHOW', false);

        getUserInfo().then(res => {
          if (res?.data?.code == 200) {
            this.$store.commit('connectWs', res?.data?.data.userId);
          }
        });
      } else {
        let timer = setTimeout(() => {
          this.$router.push('/login');
          this.$store.commit('LOGIN_IS_SHOW', true);
          clearTimeout(timer);
        }, 500);
      }
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  .flex-column;
  #main {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    background: @bg1;
    .flex-column;
    #container {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
