import Vue from 'vue';
import store from '@/vuex/store';
export const pcMinWidth = 750; // 小屏设备宽度(pc最小宽度)
import { throttle } from '../utils/utils';

// 设置 rem 函数
export const setRem = () => {
  const baseSize = 16; // 基础字号
  const clientWidth = document.documentElement.clientWidth;
  const isMobileSize = clientWidth < pcMinWidth;
  let mode = '';
  // 先判断 UA 是否为移动端设备（手机&平板）
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    mode = 'mobile';
    document.documentElement.style.fontSize = baseSize + 'px';
    // px转rem，全局挂载
    Vue.prototype.$px2rem = px => (parseFloat(px) / baseSize).toFixed(2) + 'rem'; // 放到全局
  } else {
    // 如果为桌面设备，再根据页面宽度判断是否需要切换为移动端展示
    mode = isMobileSize ? 'mobile' : 'pc';
    // 设计稿的宽度
    const uiWidth = mode == 'pc' ? 1920 : 750;
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    const scale = clientWidth / uiWidth;
    // const size = isMobileSize ? 12 : (baseSize * Math.min(scale, 2)).toFixed(2);
    const size = (baseSize * Math.min(scale, 2)).toFixed(2);
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = size + 'px';
    // px转rem，全局挂载
    Vue.prototype.$px2rem = px => ((parseFloat(px) / size) * (size / baseSize)).toFixed(2) + 'rem'; // 放到全局
  }

  Vue.prototype.$mode = mode; // 放到全局
  Vue.prototype.pcMinWidth = pcMinWidth;
  store.commit('SET_MODE', mode);

  // 改变窗口大小时重新设置 rem
  window.onresize = throttle(setRem, 200);
};
